// export const products = [
//     {
//         id : 1,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'video'
//     },  
//     {
//         id : 2,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'video'
//     },  
//     {
//         id :3,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'video'
//     },  
//     {
//         id : 4,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//          catalog: 'video'
//     },  
//     {
//         id : 5,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'Développement'
//     },  
//     {
//         id : 6,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'Développement'
//     },  
//     {
//         id : 7,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'Développement'
//     },  
//     {
//         id : 8,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'reseau'
//     },  
//     {
//         id : 9,
//         title : "RB951 UI",
//         price : "55.000",
//         category : [" Router - ", "Network"],
//         disponibility : "En Stock",
//         details : "lorem First replenish living. Creepeth image image. Creeping can't, won't called. Two fruitful let days signs sea together all land fly subdu", 
//         image : "../images/products/cablage-reseau.webp",
//         catalog: 'reseau'
//     },  

// ]

 
export const mikrotiks = [
    {
        id : 1,
        title : "RB951Ui-2HnD",
        price_sansconfig : "55.000",
        price_avecconfig : "65.000",
        category : "Routeurs Mikrotik",
        disponibility : "En Stock",
        details : ["Le RB951Ui-2HnD est un point d’accès SOHO sans fil avec un processeur Atheros de nouvelle génération et plus de puissance de traitement.", "Il dispose de cinq ports Ethernet, d’un port USB 2.0 et d’un point d’accès sans fil haute puissance 2,4 GHz 802.11b / g / n avec antennes intégrées. Il dispose d’un processeur 600 MHz, de 128 Mo de RAM et d’une fonction de sortie PoE pour le port n ° 5 – il peut alimenter d’autres appareils compatibles PoE avec la même tension que celle appliquée à l’unité. La charge maximale sur le port est de 500 mA.", "Le paquet contient RouterBOARD 951Ui-2HnD dans un boîtier en plastique et un adaptateur secteur."], 
        image : "../../../images/products/mikrotik_rb951.webp",
        ProduCode : 'RB951Ui-2HnD',
        Architecture: "MIPSBE", 
        CPU : "AR9344", 
        CPUCoreCount : "1",
        CPUNominalFrequency : "600 MHz",
        Dimensions : "113x138x29mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "128 MB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -20C +60C",
       

    },  
    {
        id : 2,
        title : "Hap Ac Lite",
        price_sansconfig : "55.000",
        price_avecconfig : "65.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Ce petit point d’accès domestique est l’appareil idéal pour les maisons ou les petits bureaux où tout ce dont vous avez besoin est un point d’accès sans fil et quelques appareils câblés connectés. Basé sur notre populaire RB951-2n, le nouveau hAP est une amélioration dans de nombreux domaines.", " L’appareil peut être alimenté par la prise d’alimentation ou par PoE passif à partir d’un injecteur PoE. L’adaptateur secteur est inclus. hAP fournit une fonction de sortie PoE pour le port n ° 5 – il peut alimenter d’autres appareils compatibles PoE avec la même tension que celle appliquée à l’unité. La charge maximale sur le port est de 500 mA.", " Le hAP est préconfiguré, il vous suffit donc de brancher le câble Internet, l’alimentation et de commencer à utiliser Internet en vous connectant au réseau MikroTik."], 
        image : "../../../images/products/hAP-ac-lite-TC-1.webp",

        ProduCode : 'RB951Ui-2HnD',
        Architecture: "MIPSBE",
        CPU : "QCA9531",
        CPUCoreCount : "1",
        CPUNominalFrequency : "650 MHz",
        Dimensions : "113 x 89 x 28mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "64 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "Flas",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -20C +60C"
    },  
 
    {
        id : 3,
        title : "Hap Ac²",
        price_sansconfig : "85.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Le hAP ac² est un point d’accès double simultané, qui fournit une couverture Wifi pour les fréquences 2,4 GHz et 5 GHz en même temps. Cinq ports Ethernet 10/100/1000 fournissent des connexions Gigabit pour vos appareils filaires, USB peut être utilisé pour le stockage externe ou un modem 4G / LTE, et l’appareil prend en charge l’accélération matérielle IPsec.", "Le nouveau boîtier universel de conception permet à l’unité d’être positionnée horizontalement (bureau) ou verticalement (boîtier tour). Un kit de montage mural est fourni."], 
        image : "../../../images/products/hapxa2.webp",

        ProduCode : 'RBD52G-5HacD2HnD-TC',
        Architecture: "ARM 32bit",
        CPU : "IPQ-4018",
        CPUCoreCount : "4",
        CPUNominalFrequency : "716 MHz",
        Dimensions : "34 x 119 x 98mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "120 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "Flash",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C to 50°C"
    },  
    {
        id : 4,
        title : "Hap Ac3",
        price_sansconfig : "90.000",
        price_avecconfig : "100.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Oubliez la recherche sans fin du routeur parfait et faites défiler une éternité de critiques et de spécifications! Nous avons créé un point d’accès résidentiel unique et abordable qui possède toutes les fonctionnalités dont vous pourriez avoir besoin pour les années à venir.", "Votre ancien routeur a-t-il du mal à atteindre tous les coins de votre maison? Eh bien, oubliez ça! Nous avons inclus des antennes sans fil externes à gain élevé avec une couverture exceptionnelle. Seule une connexion sans fil solide et stable dans chaque pièce à partir de maintenant!", "Avec 5 ports Ethernet Gigabit, 256 Mo de RAM et un processeur quad-core, hAP ​​ac³ peut satisfaire même les foyers les plus grands et les plus exigeants. Avec la prise en charge PoE, il peut alimenter ou être alimenté par d’autres appareils. Il existe un NAND de 128 Mo et un port USB pleine taille que vous pouvez utiliser pour ajouter du stockage supplémentaire. Le nouveau boîtier permet de monter l’appareil verticalement ou horizontalement. Un ensemble de montage mural est également inclus."], 
        image : "../../../images/products/hapax3.webp",

        ProduCode : 'RBD53iG-5HacD2HnD',
        Architecture: "ARM 32bit",
        CPU : "IPQ-4019",
        CPUCoreCount : "4",
        CPUNominalFrequency : "716 MHz",
        Dimensions : "251 x 129 x 39 mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "256 MB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : "-40°C to 70°C",
        IPsecHardwareAcceleration	: "Yes"
    },  
   
    {
        id : 5,
        title : "hEX",
        price_sansconfig : "55.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : [" hEX est un routeur Gigabit Ethernet à cinq ports pour les emplacements où la connectivité sans fil n’est pas requise. L’appareil dispose d’un port USB de taille normale. Cette nouvelle révision mise à jour du hEX apporte plusieurs améliorations de performances.", " Il est abordable, petit et facile à utiliser, mais est en même temps livré avec un processeur double cœur très puissant à 880 MHz et 256 Mo de RAM, capable de toutes les configurations avancées prises en charge par RouterOS.", " Le cryptage matériel IPsec (~ 470 Mbps) et le package de serveur The Dude sont pris en charge, l’emplacement microSD sur celui-ci offre une vitesse de lecture / écriture améliorée pour le stockage de fichiers et Dude."],
        image : "../../../images/products/hex.webp",

        ProduCode : 'RB750Gr3',
        Architecture: "MMIPS",
        CPU : "MT7621A",
        CPUCoreCount : "2",
        CPUNominalFrequency : "880 MHz",
        Dimensions : "113 x 89 x 28mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "256 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "Flash",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40C + 60C"
    },  
   
    {
        id : 6,
        title : "hEX lite",
        price_sansconfig : "55.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : [" hEX lite est un petit routeur Ethernet à cinq ports dans un joli boîtier en plastique. Son prix est inférieur à celui de la licence RouterOS seule", "  il n'y a tout simplement pas le choix lorsqu'il s'agit de gérer votre réseau domestique filaire, le RB750r2 (hEX lite) a tout pour plaire. Non seulement il est abordable, petit, beau et facile à utiliser, mais c'est probablement le routeur compatible MPLS le plus abordable du marché! Plus de compromis entre le prix et les fonctionnalités - RB750r2 a les deux. ", "Avec son design compact et son apparence épurée, il s'intégrera parfaitement dans n'importe quel environnement SOHO. La boîte contient: hEX lite dans un boîtier en plastique, bloc d'alimentation "],
        image : "../../../images/products/lite.webp",
        price_avecconfig : "",
        ProduCode : 'RRB750r2',
        Architecture: "MIPSBE",
        CPU : "QCA9533",
        CPUCoreCount : "1",
        CPUNominalFrequency : "850 MHz",
        Dimensions : "113 x 89 x 28mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "64 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "Flash",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 70°C"
    },  
   
    {
        id : 7,
        title : "hEX PoE lite",
        price_sansconfig : "55.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : [" hEX PoE lite est un petit routeur Ethernet à cinq ports dans un boîtier en plastique, il dispose d’un port USB 2.0 et d’une sortie PoE.", " Les ports 2 à 5 peuvent alimenter d’autres appareils compatibles PoE avec la même tension que celle appliquée à l’unité. Moins d’adaptateurs et de câbles à craindre! Le courant maximum est de 1A par port, les ports Ethernet sont blindés.", "Il est abordable, petit et facile à utiliser. Avec sa conception compacte et son apparence épurée, il s’intégrera parfaitement dans n’importe quel environnement SOHO. "],
        image : "../../../images/products/hexpoe.webp",
price_avecconfig : "",
        ProduCode : 'RB750UPr2',
        Architecture: "MIPSBE",
        CPU : "QCA9531",
        CPUCoreCount : "4",
        CPUNominalFrequency : "650 MHz",
        Dimensions : "113 x 89 x 28mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "64 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "Flash",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C +70°C"
    },  
   
    {
        id : 8,
        title : "hAP Ax Lite",
        price_sansconfig : "55.000",
        price_avecconfig : "65.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : [" Le hAP ax lite est la solution compacte idéale lorsque vous devez réduire vos coûts sans pour autant sacrifier les performances. Notre dernière puce sans fil Gen6 AX vous permet d'exploiter au maximum le spectre sans fil traditionnel de 2,4 GHz ! En fonction de votre configuration globale, vous pouvez vous attendre à une augmentation de la vitesse allant jusqu'à 90 % !", "Très utile dans les configurations évolutives comme les hôtels ou les bureaux en expansion – le prix attractif, la taille compacte et un ensemble de fonctionnalités solides permettent d’ajouter plus d’appareils sans aucun doute ! Et – en parlant d’hôtels – pourquoi ne pas emporter un hAP ax lite pour vos vacances ? Il ne prend presque pas de place dans votre valise et vous permet de vous libérer des réseaux restrictifs des invités. ", " Nos appareils hAP lite ont toujours été synonymes de rapport qualité/prix. Avec 256 Mo de RAM , un processeur ARM moderne cadencé à 800 MHz, 4 ports Gigabit Ethernet , un gain d'antenne à double chaîne beaucoup plus élevé (jusqu'à 4,3 dBi !) et une refonte visuelle complète, le nouveau hAP ax lite est un véritable pionnier en matière de rapport qualité/prix et de mise en réseau abordable."],
        image : "../../../images/products/haplite.webp",

        ProduCode : 'L41G-2axD',
        Architecture: "ARM",
        CPU : "IPQ-5010",
        CPUCoreCount : "2",
        CPUNominalFrequency : "800 MHz",
        Dimensions : "113 x 89 x 28mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "256 MB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -20C +60C"
    },  
   
    {
        id : 9,
        title : "hAP Ax²",
        price_sansconfig : "85.000",
        price_avecconfig : "95.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Il est temps de booster votre réseau domestique avec le réseau sans fil de 6e génération et notre dernier ajout à la série hAP bien-aimée. hAP ax² a tout ce dont vous pourriez avoir besoin dans un point d'accès domestique principal - et plus encore ! Oubliez les critiques et comparaisons sans fin - c'est l'appareil parfait pour 99 % des foyers. ", " Le signal sans fil est désormais plus fort que jamais. Voici les deux principaux ingrédients du succès du hAP ax : une radio bi-bande à double chaîne 4-4,5 dBi de pointe et la norme 802.11ax avec prise en charge Wave2. Comparons-le à la génération précédente : en fonction de votre configuration globale, cela signifie une vitesse jusqu'à 40 % supérieure dans le spectre 5 GHz et jusqu'à 90 % supérieure dans le spectre 2,4 GHz !", "Certains pourraient se demander pourquoi nous avons besoin de vitesses sans fil plus élevées. Eh bien, de nombreux cas d’utilisation étaient auparavant impossibles ou difficiles à réaliser. Par exemple, travailler avec des fichiers multimédias volumineux sur le réseau sans fil, sans avoir à tout télécharger et à tout charger. Avez-vous vraiment le temps d’attendre ? Procurez-vous un hAP ax² et gagnez plus de temps pour les choses essentielles de la vie ! "],
        image : "../../../images/products/hapxa2.webp",

        ProduCode : 'C52iG-5HaxD2HaxD-TC',
        Architecture: "ARM 64bit",
        CPU : "IPQ-6010",
        CPUCoreCount : "4",
        CPUNominalFrequency : "864 MHz",
        Dimensions : " - ",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS v7",
        SizeofRAM : "1 GB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40C° + 50°C"
    },  
   
    {
        id : 10,
        title : "hAP Ax3 ",
        price_sansconfig : "135.000",
        price_avecconfig : "145.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["hAP ax³ est notre appareil AX le plus puissant avec la meilleure couverture réseau sans fil à ce jour. Il dispose d'un processeur ARM quad-core moderne fonctionnant à 1,8 GHz et de suffisamment de mémoire ( 1 Go de RAM + 128 Mo de NAND ) pour la plupart des tâches. Des règles de pare-feu complexes, un cryptage matériel IPsec, Wireguard, BGP, un routage avancé ou plusieurs tunnels VPN de travail à distance n'empêcheront pas votre famille de naviguer confortablement, de diffuser en continu, de jouer, etc. Il y a suffisamment de puissance de traitement pour tout le monde. Et nous avons ajouté un port USB 3 rapide pour tous vos besoins de stockage ou un modem LTE supplémentaire. ", "En fonction de votre configuration globale, notre gamme de produits AX offre jusqu'à 40 % de vitesse supérieure dans la bande des 5 GHz et jusqu'à 90 % de vitesse supérieure dans la bande des 2,4 GHz ! Des antennes externes puissantes permettent d'atteindre un gain allant jusqu'à 5,5 dBi, vous pouvez donc oublier les amplificateurs Wi-Fi et autres astuces. Une connectivité fluide et rapide pour tout votre appartement - c'est ainsi que fonctionne hAP ax³. Les vitesses du réseau domestique deviennent sérieuses. Il y a 4 ports Gigabit Ethernet et un port 2,5 Gigabit ultra-rapide. "],
        image : "../../../images/products/hapax3.webp",

        ProduCode : 'C53UiG+5HPaxD2HPaxD',
        Architecture: "ARM 64bit",
        CPU : "IPQ-6010",
        CPUCoreCount : "4",
        CPUNominalFrequency : "auto (864 - 1800) MHz",
        Dimensions : " - ",
        RouterOSLicense :	" 6 ",
        OperatingSystem : "RouterOS v7",
        SizeofRAM : "1 GB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -20C +60C",
        IPsecHardwareAcceleration	: "yes"
    },  
   
    {
        id : 11,
        title : "RB2011UiAS-2HnD-IN",
        price_sansconfig : "90.000",
        price_avecconfig : "105.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Le RB2011Ui est une série d'appareils multiports à faible coût. Conçu pour une utilisation en intérieur et disponible dans de nombreux cas différents, avec une multitude d'options. ", " Le RB2011 est alimenté par RouterOS, un système d'exploitation de routage complet qui a été continuellement amélioré depuis quinze ans. Routage dynamique, point d'accès, pare-feu, MPLS, VPN, qualité de service avancée, équilibrage et liaison de charge, configuration et surveillance en temps réel - juste quelques-unes des nombreuses fonctionnalités prises en charge par RouterOS.", "Le RouterBOARD 2011UiAS-2HnD possède la plupart des fonctionnalités et interfaces de tous nos routeurs sans fil. Il est alimenté par le nouveau processeur réseau Atheros 600 MHz 74K MIPS, dispose de 128 Mo de RAM, de cinq ports LAN Gigabit, de cinq ports LAN Fast Ethernet et d'une cage SFP (module SFP non inclus !). FrançaisDe plus, il est doté d'un puissant point d'accès sans fil 802.11bgn à double chaîne de 2,4 GHz (2312-2732 MHz selon la réglementation du pays), d'un port série RJ45, d'un port USB et d'une licence RouterOS L5, ainsi que d'un boîtier de bureau avec alimentation, de deux antennes Omni 4 dBi et d'un panneau LCD."],
        image : "../../../images/products/2011.webp",

        ProduCode : 'RBD53iG-5HacD2HnD',
        Architecture: "MIPSBE",
        CPU : "AR9344",
        CPUCoreCount : "4",
        CPUNominalFrequency : "600 MHz",
        Dimensions : "214 mm x 86 mm",
        RouterOSLicense :	"5",
        OperatingSystem : "RouterOS",
        SizeofRAM : "128 MB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40C + 60C"
    },  
    {
        id : 12,
        title : "RB3011UiAS-RM",
        price_sansconfig : "150.000",
        price_avecconfig : "165.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Le RB3011 est un nouveau périphérique multiport, le premier à utiliser un processeur à architecture ARM pour des performances plus élevées que jamais. Le RB3011 dispose de dix ports Gigabit divisés en deux groupes de commutateurs, d'une cage SFP et, pour la première fois, d'un port USB 3.0 SuperSpeed ​​de taille standard, pour ajouter du stockage ou un modem 3G/4G externe.", "L'unité RB3011UiAS-RM est livrée avec un boîtier de montage en rack 1U, un écran LCD tactile, un port de console série et une fonctionnalité de sortie PoE sur le dernier port Ethernet."],
        image : "../../../images/products/3011.webp",

        ProduCode : 'RB3011UiAS-RM',
        Architecture: "ARM 32bit",
        CPU : "IPQ-8064",
        CPUCoreCount : "2",
        CPUNominalFrequency : "1.4GHz",
        Dimensions : "443 x 92 x 44 mm",
        RouterOSLicense :	"5",
        OperatingSystem : "RouterOS v7",
        SizeofRAM : "1 GB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -20°C + 70°C"
    },  
    {
        id : 13,
        title : "RB4011iGS+RM",
        price_sansconfig : "205.000",
        price_avecconfig : "220.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Série RB4011 - routeurs incroyablement puissants avec dix ports Gigabit, interface SFP+ 10 Gbps et accélération matérielle IPsec à un prix avantageux !", "Le RB4011 utilise un processeur Cortex A15 à quatre cœurs, identique à celui de notre unité RB1100AHx4 de qualité opérateur. L'unité est équipée de 1 Go de RAM, peut fournir une sortie PoE sur le port n° 10 et est livrée avec un boîtier métallique solide, compact et professionnel, de couleur noir mat.", "Le RB4011iGS+RM (modèle Ethernet) comprend deux oreilles de montage en rack qui fixeront solidement l'unité dans un espace rack 1U standard."],
        image : "../../../images/products/rb4011.webp",

        ProduCode : 'RB4011iGS+RM',
        Architecture: "ARM 32bit",
        CPU : "AL21400",
        CPUCoreCount : "4",
        CPUNominalFrequency : "	auto (533 - 1900) MHz",
        Dimensions : "228 x 120 x 30 mm",
        RouterOSLicense :	"5",
        OperatingSystem : "RouterOS v7",
        SizeofRAM : "1 GB",
        StorageSize	 : "512 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 70°C"
    },  
    {
        id : 14,
        title : "CCR1009-7G-1C-1S+",
        price_sansconfig : "55.000",
        price_avecconfig : "65.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["La nouvelle révision mise à jour de CCR1009 est ici. Nous avons tenté de combiner tous les commentaires des clients et les meilleures pratiques en matière de fabrication d’appareils CCR que nous avons apprises au cours des trois dernières années, depuis le lancement des premiers appareils CCR1009.", "Pas de puce de commutation – l’appareil ne dispose désormais que de ports Ethernet entièrement indépendants, chacun avec une connexion directe au processeur, ce qui permet de surmonter la limitation précédente partagée de 1 Gbit des ports de puce de commutation et d’utiliser tout le potentiel de la puissance de traitement du processeur sur ces ports.","Combo-port – une seule interface logicielle 1 Gbit qui a deux interfaces matérielles – une cage SFP et un port Gigabit Ethernet, vous permettant d’utiliser tout type de connexion disponible. Il est également possible de basculer entre les deux interfaces physiques dans RouterOS. En cas de déconnexion, le port combiné offre une fonction de basculement matériel."],
        image : "../../../images/products/CCR1009-7G-1C-1S+.webp",

        ProduCode : 'CCR1009-7G-1C-1S+',
        Architecture: "TILE",
        CPU : "TLR4-00980",
        CPUCoreCount : "9",
        CPUNominalFrequency : "1200 MHz",
        Dimensions : "443 x 175 x 44 mm",
        RouterOSLicense :	"6",
        OperatingSystem : "RouterOS",
        SizeofRAM : "2 GB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 60°C"
    },  
    {
        id : 15,
        title : "CCR1016-12G",
        price_sansconfig : "55.000",
        price_avecconfig : "65.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["Le CCR1016-12G est un routeur de qualité industrielle avec un processeur de pointe à 16 cœurs. Si vous avez besoin de plusieurs millions de paquets par seconde, le routeur Cloud Core est votre meilleur choix. L’unité est équipée de 12 ports Gigabit Ethernet et d’un écran LCD couleur tactile.", "La nouvelle version r2 intègre 2 Go de RAM, un emplacement USB pleine taille et un double bloc d’alimentation pour la redondance!"],
        image : "../../../images/products/CCR1016-12G.webp",

        ProduCode : 'CCR1016-12G',
        Architecture: "TILE",
        CPU : "TLR4-01680",
        CPUCoreCount : "16",
        CPUNominalFrequency : "1200 MHz",
        Dimensions : "443 x 175 x 44 mm",
        RouterOSLicense :	"6",
        OperatingSystem : "RouterOS",
        SizeofRAM : "2 GB",
        StorageSize	 : "512 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -20°C + 60°C"
    },  
    {
        id : 16,
        title : "RB760iGS",
        price_sansconfig : "75.000",
        price_avecconfig : "65.000",
        category : "Mikrotik",
        disponibility : "En Stock",
        details : ["hEX S est un routeur Gigabit Ethernet à cinq ports pour les emplacements où la connectivité sans fil n’est pas requise. Par rapport au hEX, le hEX S dispose également d’un port SFP et d’une sortie PoE sur le dernier port.", "Il est abordable, petit et facile à utiliser, mais est en même temps livré avec un processeur double cœur très puissant de 880 MHz et 256 Mo de RAM, capable de toutes les configurations avancées prises en charge par RouterOS. L’appareil dispose d’une sortie USB 2.0, PoE pour le port Ethernet n ° 5 et d’une cage SFP à 1,25 Gbit / s.", "Le cryptage matériel IPsec (~ 470 Mbps) et le package de serveur The Dude sont pris en charge, l’emplacement microSD sur celui-ci offre une vitesse de lecture / écriture améliorée pour le stockage de fichiers et Dude."],
        image : "../../images/products/haps.webp",

        ProduCode : 'RB760iGS',
        Architecture: "MMIPS",
        CPU : "MT7621A",
        CPUCoreCount : "2",
        CPUNominalFrequency : "880 MHz",
        Dimensions : "113 x 89 x 28 mm",
        RouterOSLicense :	"4",
        OperatingSystem : "RouterOS",
        SizeofRAM : "256 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "FLASH",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 70°C"
    },  
   
    {
        id : 17,
        title : "LHG 5",
        price_sansconfig : "80.000",
        category : "Mikrotik antenne",
        disponibility : "En Stock",
        details : ["Le LHG 5 est un appareil sans fil 5 GHz 802.11 a / n compact et léger avec une antenne grille intégrée à double polarisation de 24,5 dBi à un prix révolutionnaire. Il est parfait pour les liaisons point à point ou pour une utilisation comme CPE sur de plus longues distances et prend en charge le protocole Nv2 TDMA.", "La conception de la grille assure une protection contre le vent et le fait que l’élément d’antenne soit intégré à l’unité sans fil signifie aucune perte sur les câbles."],
        image : "../../images/products/LHG-5-ac.webp",

        ProduCode : 'RBLHG-2nD',
        Architecture: "MIPSBE",
        CPU : "AR9344",
        CPUCoreCount : "1",
        CPUNominalFrequency : "600 MHz",
        Dimensions : "Ø 391 x 222 mm; package 450 x 450 x 145 mm",
        RouterOSLicense :	"3",
        OperatingSystem : "RouterOS",
        SizeofRAM : "64 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "FLASH",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 70°C"
    },  
    
    {
        id : 18,
        title : "LHG 2",
        price_sansconfig : "87.000",
        price_avecconfig : "65.000",
        category : "Mikrotik antenne",
        disponibility : "En Stock",
        details : ["Le Light Head Grid (LHG) est un appareil sans fil compact et léger 2,4 GHz 802.11b / g / n avec une antenne grille à double polarisation intégrée de 18 dBi à un prix révolutionnaire. Il est parfait pour les liaisons point à point ou pour une utilisation comme CPE sur de plus longues distances et prend en charge le protocole Nv2 TDMA.", "La conception de la grille assure une protection contre le vent et le fait que l’élément d’antenne soit intégré à l’unité sans fil signifie aucune perte sur les câbles."],
        image : "../../images/products/LHG2.webp",

        ProduCode : 'RBLHG-2nD',
        Architecture: "MIPSBE",
        CPU : "QCA9533",
        CPUCoreCount : "1",
        CPUNominalFrequency : "650 MHz",
        Dimensions : "391 x 222 mm",
        RouterOSLicense :	"3",
        OperatingSystem : "RouterOS",
        SizeofRAM : "64 MB",
        StorageSize	 : "16 MB",
        StorageType	 : "FLASH",
        MTBF	 : "Approximately 100’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 70°C"
    },  
    {
        id : 19,
        title : "RB5009UPr+S+IN",
        price_sansconfig : "195.000",
        price_avecconfig : "210.000",
        category : "Mikrotik ",
        disponibility : "En Stock",
        details : ["Cette version du RB5009 possède toutes les fonctionnalités du modèle précédent : Gigabit Ethernet, 2,5 Gigabit Ethernet et une cage SFP+ 10 Gigabit pour la connectivité fibre optique. Il offre la vitesse, la puissance et la durabilité. Mais cette fois, nous avons ajouté PoE-in et PoE-out sur les huit ports Ethernet. Associés au connecteur à 2 broches et à la prise CC, vous obtenez 10 modes d'alimentation distincts. Les alimentations doubles redondantes permettent généralement d'améliorer la disponibilité ininterrompue. Le RB5009UPr+S+IN va encore plus loin !", "Toutes les options d'alimentation prennent en charge une large plage de tension de 24 à 57 V. Cependant, vous ne pouvez pas mélanger les tensions. Si vous utilisez la sortie PoE pour alimenter d'autres appareils, la carte choisira la source avec la tension la plus élevée (prise CC ou connecteur à 2 broches) pour les alimenter."],
        image : "../../images/products/RB5009UGSIN.webp",

        ProduCode : 'RB5009UPr+S+IN',
        Architecture: "ARM 64bit",
        CPU : "88F7040",
        CPUCoreCount : "4",
        CPUNominalFrequency : "350-1400 (auto) MHz",
        
        RouterOSLicense :	"5",
        OperatingSystem : "RouterOS (V7 Only)",
        SizeofRAM : "1GB",
        StorageSize	 : "1GB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 200’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 60°C"
    },  
    {
        id : 20,
        title : "RB1100AHx4",
        price_sansconfig : "300.000",
        price_avecconfig : "320.000",
        category : "Mikrotik ",
        disponibility : "En Stock",
        details : ["Présentation du routeur RB1100AHx4, 13 ports Gigabit Ethernet, alimenté par un processeur Annapurna Alpine AL21400 avec quatre cœurs Cortex A15, cadencés à 1,4 GHz chacun, pour un débit maximal allant jusqu'à 7,5 Gbit. L'appareil prend en charge l'accélération matérielle IPsec (jusqu'à 2,2 Gbit/s avec AES128).", "L'unité est livrée avec un boîtier de montage en rack 1U, un port série RS232 et deux alimentations redondantes (avec alimentation télécom -48 V CC et prise en charge 802.3at/af)."],
        image : "../../images/products/RB1100AHx4.webp",

        ProduCode : 'RB1100x4',
        Architecture: "ARM 32bit",
        CPU : "AL21400",
        CPUCoreCount : "4",
        CPUNominalFrequency : "1.4 GHz",
        
        RouterOSLicense :	"6",
        OperatingSystem : "RouterOS ",
        SizeofRAM : "1GB",
        StorageSize	 : "128MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 200’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 60°C"
    },  
    {
        id : 21,
        title : "RB5009UPr+S+IN",
        price_sansconfig : "195.000",
        price_avecconfig : "210.000",
        category : "Mikrotik ",
        disponibility : "En Stock",
        details : ["Le modèle L009 est bien plus qu’un simple routeur. Cette gamme de produits est jusqu’à 4 fois plus rapide que le RB2011. Il est équipé d’un processeur ARM moderne avec prise en charge des conteneurs, d’un boîtier innovant permettant le montage de jusqu’à quatre routeurs dans un espace de 1U, d’une plus grande quantité de RAM, de la fonctionnalité PoE et du support SFP 2.5G.", "Le L009 est doté d'un puissant processeur ARM double cœur . Il offre une amélioration significative en matière de routage et de filtrage, de règles de pare-feu complexes, de cryptage matériel IPsec et de diverses fonctionnalités avancées de RouterOS. L'architecture du processeur ARM offre des avantages significatifs par rapport aux anciens appareils basés sur MIPS. Comme l'utilisation de toutes les dernières fonctionnalités du noyau Linux. Ou la mise en œuvre de vos propres projets de conteneurs. La combinaison d'un processeur ARM moderne et d'un port USB pleine taille est parfaite pour exécuter des projets de conteneurs personnalisés. Vous pouvez exécuter un adblock Pi-hole sur ce routeur, par exemple. Ou vous pouvez utiliser l'USB pour ajouter un modem LTE de votre choix. L'imagination est la vraie limite ici."],
        image : "../../images/products/l009.webp",

        ProduCode : 'L009UiGS-RM',
        Architecture: "ARM ",
        CPU : "IPQ-5018",
        CPUCoreCount : "2",
        CPUNominalFrequency : "800 MHz",
        
        RouterOSLicense :	"5",
        OperatingSystem : "RouterOS ",
        SizeofRAM : "512 MB",
        StorageSize	 : "128 MB",
        StorageType	 : "NAND",
        MTBF	 : "Approximately 200’000 hours at 25C",
        TestedAmbientTemperature : " -40°C + 70°C"
    },  
   
 
   

]
export const networks = [
    {
        id : 1,
        title : "Connecteur RJ45 mâle Pass Through",
        price : "5.000",
        category : "Réseau informatique, Connecteur",
        disponibility : "En Stock",
        details : ["Fiche RJ45 mâle de catégorie 6, contacts plaqués or.", "Blindage type FTP.", "Le principal avantage du connecteur Pass Through, dit également à fils débouchants, est que le câblage est plus facile et rapide car il n'est pas besoin de couper les 8 fils à une longueur précise. Les 8 fils passent à travers le connecteur, la coupe est réalisée par la pince à sertir." ], 
        image : "../../../images/products/connecteur_rj45.webp",
        features :[ "Débouchant", "FTP", "Solide"]
    },  
    {
        id : 2,
        title : "D-Link Switch 5 Ports Gigabit - Métallique DGS-105 - D-link",
        price : "15.000",
        category : "Réseau informatique, Switch",
        disponibility : "En Stock",
        details : ["Destinés aux petites et moyennes entreprises, les switches Gigabit Ethernet DGS-105 et DGS-108 de D-Link permettent de créer un petit réseau de façon simple, rapide et économique et de relier des ordinateurs, des points d'accès sans fil, des caméras IP, des imprimantes réseau et d'autres périphériques Ethernet sans passer par des opérations de configuration complexes.", "IGMP Snooping Lorsque le IGMP Snooping (Internet Group Management Protocol) est activé, le DGS-105 écoute les conversations IGMP entre les hôtes et les routeurs et maintient une carte des liaisons nécessitant des flux de multidiffusion IP, ce qui se traduit par une plus grande efficacité et des performances plus fluides Internet.Tous les liens qui n’ont pas besoin de multidiffusion peuvent être filtrés, de sorte que vous contrôlez toujours les ports qui reçoivent spécifiquement le trafic de multidiffusion"], 
        image : "../../../images/products/5ports_gigabit.webp",
        features : ["5 ports Gigabit Ethernet", "Fontionnement Plug & play", "IGMP Snooping", "Technologie économe en énergie", "Compact et silencieux", "Fonction de diagnostic des câbles", "Jeux en ligne 4k streaming vidéo"],

    },  
    
    {
        id : 3,
        title : "tenda Switch Tenda 8 Ports Gigabit-boîtier Métallique",
        price : "20.000",
        category : "Réseau informatique, Switch",
        disponibility : "En Stock",
        details : ["Destinés aux petites et moyennes entreprises, les switches Gigabit Ethernet DGS-105 et DGS-108 de D-Link permettent de créer un petit réseau de façon simple, rapide et économique et de relier des ordinateurs, des points d'accès sans fil, des caméras IP, des imprimantes réseau et d'autres périphériques Ethernet sans passer par des opérations de configuration complexes.", "IGMP Snooping Lorsque le IGMP Snooping (Internet Group Management Protocol) est activé, le DGS-105 écoute les conversations IGMP entre les hôtes et les routeurs et maintient une carte des liaisons nécessitant des flux de multidiffusion IP, ce qui se traduit par une plus grande efficacité et des performances plus fluides Internet.Tous les liens qui n’ont pas besoin de multidiffusion peuvent être filtrés, de sorte que vous contrôlez toujours les ports qui reçoivent spécifiquement le trafic de multidiffusion"], 
        image : "../../../images/products/tenda-switch-de-bureau-8-ports-gigabit-10-100-1000.webp",
        features : ["8 ports Gigabit Ethernet", "Fontionnement Plug & play", "IGMP Snooping", "Technologie économe en énergie", "Compact et silencieux", "Fonction de diagnostic des câbles", "Jeux en ligne 4k streaming vidéo"],

    },  
    {
        id : 4,
        title : "TP Link TP-Link TL-SG1016D 16-Port Gigabit Desktop Switch",
        price : "75.000",
        category : "Réseau informatique, Switch",
        disponibility : "En Stock",
        details : ["Le switch Gigabit 16 ports TL-SG1016D fournit un moyen facile de faire la transition vers Ethernet Gigabit. Les 16 ports sont compatibles Auto MDI/MDIX, nul besoin de vous soucier des types de câbles : vous branchez et ça marche. De plus, grâce à sa technologie innovante d'économie d'énergie, le TL-SG1016D permet de réduire de 20 %* sa consommation d'énergie, ce qui en fait une solution écologique adaptée à votre réseau domestique ou professionnel.Le switch Gigabit 16 ports TL-SG1016D fournit un moyen facile de faire la transition vers Ethernet Gigabit. Les 16 ports sont compatibles Auto MDI/MDIX, nul besoin de vous soucier des types de câbles : vous branchez et ça marche. De plus, grâce à sa technologie innovante d'économie d'énergie, le TL-SG1016D permet de réduire de 20 %* sa consommation d'énergie, ce qui en fait une solution écologique adaptée à votre réseau domestique ou professionnel."], 
        image : "../../../images/products/switch16_ports.webp",
        features : ["16 ports RJ45 10/100/1000 Mbps", "Technologie de gestion d'alimentation innovante pouvant générer jusqu'à 20% d'économie d’énergie", "Prise en charge de l’auto-apprentissage d'adresse, auto MDI/MDIX et auto-négociation"],

    },  
    {
        id : 5,
        title : "DGS-1024D Switch 24 Ports 10/100/1000 Mbps",
        price : "55.000",
        category : "Réseau informatique, Switch",
        disponibility : "En Stock",
        details : ["Gigabit cuivre 24 portsAvec une installation plug-and-play et les ports Gigabit cuivre capable de se connecter à votre câble paires torsadées Cat.5 existant, ces produits améliorent instantanément les performances de votre groupe de travail sans que vous ayez besoin d'installer des câbles la fibre optique ou de refaire une configuration complexe. Tous les ports supportent les réseaux 10/100/1000 Mbps à détection automatique et semi-duplex.", "Serveur d'accès Gigabit full duplexLes ports Gigabit Fournit des bandes passantes dédiées pour que vos ordinateurs de bureau fonctionnent à duplex de 2000 Mbps. Ceci évite les goulots d'étranglement et permet aux stations de travail de se connecter simultanément au serveur de données.","Contrôle de flux IEEE 802.3xCette fonction permet aux serveurs de communiquer directement avec les transferts de données rapides et efficaces. Un duplex intégral de 2000 Mbits / s permet de transférer des données vers le bas avec des pertes de transfert de données minimales.", "Auto-ajustement des ports en MDI / MDI-XMDI / MDI-X, eliminant need to cables and croised our ports of uplink. N'importe quel port peut être connecté à un serveur, à un hub ou à un autre passer en utilisant un câble de droit torsadées traditionnel."], 
        image : "../../../images/products/switch24_port.webp",
        features : ["24 ports Gigabit Ethernet", "Fontionnement Plug & play", "IGMP Snooping", "Technologie économe en énergie", "Compact et silencieux", "Fonction de diagnostic des câbles", "Jeux en ligne 4k streaming vidéo"],

    },  
    {
        id : 6,
        title : "SWITCH POE 8 PORT",
        price : "48.000",
        category : "Réseau informatique, Switch",
        disponibility : "En Stock",
        details : ["Commutateur PoE+ 8 ports Gigabit fiable de 120 watts. Le GS1010PE contient 10 ports RJ45 gigabit, les ports #1 à #8 prennent en charge les fonctions PoE qui pourraient offrir un budget PoE total de 120 watts, tandis que les ports #9 à #10 ne sont que des ports Ethernet de liaison montante qui ne transfèrent que des données.", "8 ports Gigabit PoE+ Idéal pour les petites installations de 8 appareils PoE ou PoE+ (téléphone IP, caméra de surveillance filaire, point d'accès sans fil). 2 ports de liaison montante Gigabit conviennent respectivement à la connexion à Internet et au NVR, ce qui est très approprié pour les applications de vidéosurveillance et d'accès WiFi.","Compatible avec tout appareil IEEE802.3af PoE et IEEE802.3at PoE+. La technologie de détection automatique fournit jusqu'à 30 W à un appareil 802.3at PoE+ (30 watts) avec une rétrocompatibilité pour les appareils 802.3af PoE (15,4 watts). Ne prend pas en charge les périphériques PoE passifs (non standard)."], 
        image : "../../../images/products/switch_poe_8ports.webp",
        features : ["8 ports PoE Gigabit Ethernet", "Fontionnement Plug & play", "IGMP Snooping", "Technologie économe en énergie", "Compact et silencieux", "Fonction de diagnostic des câbles", "Jeux en ligne 4k streaming vidéo"],

    },  
    {
        id : 7,
        title : "Switch PoE 4 Ports",
        price : "55.000",
        category : "Réseau informatique, Switch",
        disponibility : "En Stock",
        details : ["Switch PoE 4 Ports 300 M de distance"], 
        image : "../../../images/products/Switch_PoE_4_Ports.webp",
        features : ["PoE", "300M de distance", "4 ports PoE", "1 Port Lan"],

    },  
    {
        id : 8,
        title : "Câble réseau SFTP cat6 outdoor blindé",
        price : "55.000",
        category : "Réseau informatique, Cable réseau ",
        disponibility : "En Stock",
        details : ["10BASE-T,10BASE-TX,10BASE-T4,1000BASE-T,tLa bande passante efficace de la couche de date est 1Gbit/s(Cat6),10Gbit/s(Cat6A).", "La distance de transmission maximale est de 100 mètres. La structure d’isolation croisée et la gaine plus épaisse évitent efficacement la diaphonie entre chaque paire de câble. Structure de bouclier unique, faire le câble ont une forte capacité anti-interférence au monde extérieur et transmettre le signal en toute confiance."], 
        image : "../../../images/products/cable.png",
        features : ["Paire torsadée 4P + F + MAB", "Cuivre nu solide ou toronné", "HD-PE"],

    },  
    {
        id : 9,
        title : "Cable Rj45 Cat6e UTP 1m",
        price : "1.500",
        category : "Réseau informatique, Switch",
        disponibility : "En Stock",
        details : ["Cable Rj45 cat6e UTP","La vitesse, la fiabilité et la performance au bout de vos doigts ! Découvrez notre câble UTP Cat6e pour des connexions ultra-rapides. L’avenir de la connectivité est là."], 
        image : "../../../images/products/03019_1-vimar-net-safe-cordone-rj45-cat6-u-utp-1m.56878.webp",
        features : ["Paire torsadée 4P + F + MAB", "Cuivre nu solide ou toronné"],

    },  
    {
        id : 10, 
        title : "CAT6A RJ45 Keystone Jack in FTP",
        price : "1500",
        category : "Réseau informatique, connecteurs",
        details : "Outil De Terminaison Facile Pour Prise Keystone À 90 Degrés",
        disponibility : "En Stock",
        features : ["Les connecteurs blindés CAT6A offrent d’excellentes performances pour les réseaux de données.", "Prend en charge l’alimentation via Ethernet (PoE++) de type 4 jusqu’à 100 watts.", "L’annulation améliorée de la diaphonie réduit la perte de retour et améliore les performances en rejetant le bruit et les signaux indésirables.", "Conçu pour fournir un capuchon de terminaison de fil pour une terminaison rapide et facile."], 
        image : "../../../images/products/cat6a-rj45-keystone-jack-ftp-hd-style-ic1078s6a0.webp",

    },  
    {
        id : 11,
        title : "Flat cable CAT6A FTP 2m gigabit",
        price : "3000",
        category : "Réseau informatique, connecteurs",
        details : "Ce câble réseau Cat6A à profil bas de haute qualité est idéal pour les connexions Gigabit et 10 Gigabit. ",
        disponibility : "En Stock",
        features : [ "Les câbles Cat 6A sont rétrocompatibles avec les appareils Cat6 et Cat 5E.", "Conforme CAT6A 10Gigabit Conforme"], 
        image : "../../../images/products/flat-cable-blinde.webp",

    },  
    {
        id : 12,
        title : "Fiber Optic Ethernet Media Converter gigabit 10/100/1000M Single-mode",
        price : "25.000",
        category : "Réseau informatique, connecteurs",
        details : "Convertisseur de Média Ethernet Fibre Optique Gigabit Netlink 1000Mbps",
        disponibility : "En Stock",
        features : [ "1 x Convertisseur de Média Ethernet Fibre Optique Gigabit Netlink 1000Mbps – HTB-GS-03A.", "1 x Convertisseur de Média Ethernet Fibre Optique Gigabit Netlink 1000Mbps – HTB-GS-03B", "2 x puissance"], 
        image : "../../../images/products/media_converter.webp",

    },  
    {
        id : 13,
        title : "Coupleur Rj45 UTP Femelle Vers Femelle",
        price : "500",
        category : "Réseau informatique, connecteurs",
        details : "Coupleur RJ45 femelle vers femelle pour câble réseau cat6",
        disponibility : "En Stock",
        features : ["Fonction coupleur RJ45 : connectez deux câbles Ethernet courts ensemble pour obtenir un choix idéal pour rallonger le câble Ethernet ",], 
        image : "../../../images/products/coupleur-rj45.webp",

    },  
]
export const accessories = [
    {
        id : 1,
        title : "Ram Walram DDR3L Portable RAM 8 GB 1866Mhz",
        price : "12.000",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : "Que ce soit un utilisateur général, des joueurs ou des amateurs d'ordinateurs, peut sentir la mémoire WALRAM apporter l'amélioration des performances pour l'ordinateur. Basée sur une technologie autorisant un plus grand nombre de gigabits par composant, la mémoire Walram DDR3L s’appuie sur des puces haute densité permettant de proposer des modules DDR3L jusqu’à deux fois plus denses.", 
        image : "../../../images/products/ram8gb.webp",
    },  
    {
        id : 2,
        title : "Crucial Mémoire RAM DDR4 3200AA 16GB / 16 GB - PC Portable",
        price : "25.000",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["Tirez le meilleur profit de votre système.", "Pour seulement une fraction du prix d’un nouvel ordinateur, une mise à niveau de mémoire est l’un des moyens les plus économiques pour améliorer les performances de votre système. Utilisez votre ordinateur portable au maximum de ses performances en lui offrant les ressources dont il a besoin."], 
        image : "../../../images/products/ram16gb.webp",
    },  
    {
        id : 3,
        title : "Tapis De Souris Modèle Carte Du Monde 900 * 400 Mm",
        price : "3700",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["La surface du tapis de souris est en maille fine pour une excellente fonction de suivi de la souris et la base en mousse antidérapante offre une prise parfaite sur le Bureau."], 
        image : "../../../images/products/tapisdesouris.webp",
    },  
    { 
        id : 4,
        title : "Benfei Hub USB C Vers HDMI, 3 Ports USB-C Vers USB, USB C Vers Carte SD/TF, Charge PD",
        price : "25.000",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["Extension instantanée: ajoutez 1*HDMI, 3*USB 3.0, 1*Lecteur de micro SD, 1*Lecteur de SD, 1*PD 60W, le tout à partir d'un seul port USB-C.✅Note:vous utilisez un smartphone ou un iPad avec le Hub, veuillez brancher le port PD du Hub pour le charger afin de garantir suffisamment de puissance pour la sortie vidéo. Sinon, la fonction de sortie vidéo du Hub pourrait ne pas fonctionner ou être instable."], 
        image : "../../../images/products/station.webp",
    },  
    { 
        id : 5,
        title : "Kit réseau informatique - Pince à sertir - testeur - RJ-45 - connecteur - dénudeur",
        price : "12.500",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["Kit complet 9 en 1 : Inclut pince à sertir, testeur de câble, couteau à dénuder, tournevis, outil de poinçonnage et connecteurs RJ45."], 
        image : "../../../images/products/network_kit.webp",
    },  
    { 
        id : 6,
        title : "Souris éclairée Bluetooth Sans Fil Rechargeable-noir",
        price : "2.500",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : [" La souris à clic silencieux avec un design classique avec une finition anti-empreintes digitales offre à votre main un maximum de soutien et de confort. La molette de défilement en caoutchouc antidérapante robuste garantit que votre main ne glissera pas lors du défilement.【Souris sans fil optique rechargeable】 La batterie rechargeable au lithium durable de 500 mAh intégrée peut durer jusqu'à 500 heures après une charge complète, et le temps de veille est très long avec le mode veille et réveil automatique. Se recharge facilement via le câble USB inclus, pas besoin de changer la batterie.【SANS BRUIT】 Son de clic silencieux lorsque vous appuyez sur les boutons, ce qui vous évite de vous soucier de déranger les autres, en particulier à la maison pendant que les autres membres de la famille se reposent, afin que vous puissiez rester concentré sur votre travail."], 
        image : "../../../images/products/mouse_wireless.webp",
    },  
    { 
        id : 7,
        title : "Adaptateur WiFi - 600Mbps USB - Ethernet",
        price : "3.500",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["Comprend la fonction de transmission et de réception WiFi et le partage de réseau. En cas d'absence de routeur, insérez l'adaptateur WiFi dans le PC qui accède au réseau, pour permettre à la fonction WiFi de transmettre le signal. Conçu en petite taille, ce qui est délicat et occupe peu d'espace, pour un transport et une utilisation faciles. Sans câble, adapté à un ordinateur portable ou à un ordinateur de bureau, vous permettant de profiter facilement du WiFi sans fil. Prise en charge plug and play, aucun pilote requis, plus pratique à utiliser. Prend en charge 64/128 bits WEP WAP/WAP2 WAP-PSK/WAP2-PSK (TKIP/AES). Compatible avec Windows XP/Vista/Win7/Win8/Win10."], 
        image : "../../../images/products/adapter-wifi.webp",
    },  
    { 
        id : 8,
        title : "KASPERSKY- PLUS 2023 - 4 Postes - Français",
        price : "15.000",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["Sécurité. Performance. Confidentialité. Le tout dans un seul logiciel. Kaspersky Plus optimise votre vie en ligne grâce aux outils de sécurité nouvelle génération. Navigation privée sans interruptionOutils empêchant la surveillance en ligne non autorisée, l’apparition de publicités et l’utilisation de vos périphériques. Protection des données personnelles et de paiementProtégez vos applications et opérations bancaires, et conservez vos identifiants de connexion à portée de main en toute sécurité. VPN rapide et illimitéNaviguez en toute confidentialité et en toute liberté sur Internet, sans renoncer à la vitesse. Nettoyage du disque dur et surveillance de son intégritéSolutions permettant de gérer l'espace de stockage de votre appareil, de procéder à un bilan de santé de votre disque dur et d'éviter la perte de vos données. Optimisation des performancesDes mesures rapides pour booster la vitesse de vos appareils et leur permettre de fonctionner aussi vite qu'au premier jour."], 
        image : "../../../images/products/kaspersky.webp",
    },  
    { 
        id : 9,
        title : "Multi HUB Splitter D'extension USB 3.0 à Haute Vitesse 4 Ports USB 3.0",
        price : "2.500",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["Dispositif USB 3.0, entièrement rétrocompatible avec les dispositifs USB 1.1 et USB 2.0. Hub USB 3.0 à 4 ports avec un taux de transfert super rapide allant jusqu'à 5Gbps. Étend les ports de votre ordinateur et utilise plus de périphériques, permet d'utiliser ou de charger jusqu'à 4 accessoires USB simultanément. Léger, petit et mince, facile à mettre dans votre poche et à transporter. Plug and play, installation facile et utilisation sûre."], 
        image : "../../../images/products/hubsplitter.webp",
    },  
    { 
        id : 10,
        title : "Boitier Disque Dur Externe SATA 3.0 + Câble Type C",
        price : "5.000",
        category : "Accesoires informatique",
        disponibility : "En Stock",
        details : ["Compatibilité : la taille fine est compatible avec tous les disques durs SSD SATA standard de 7 mm/9,5 mm de 2,5\", qui prennent en charge une capacité de disque dur SSD allant jusqu'à 6 To. Système d'exploitation : convient pour 98/SE/ ME/2000/XP/Vista/Win7/Win8/Win10 ou la dernière version. Matériau : fabriqué en matériau ABS, durable et durable. Installation : installation sans outil, hot plug and play, voyant LED, pas besoin de redémarrage ou de pilotes supplémentaires. Interface : interface USB3.0, avec une vitesse de transfert allant jusqu'à 6 Gbit/s, compatible avec USB 2.0 et USB1.1."], 
        image : "../../../images/products/boitier_disque_dur.webp",
    },  


]