// src/dataTrainings.js
export const trainings = [
{
        id: 1,
        title: "Infographie",
        introduction : "L'infographie est un puissant outil de communication visuelle qui combine art et information pour rendre les données complexes accessibles et compréhensibles. En utilisant des éléments visuels tels que des icônes, des graphiques, des diagrammes et des couleurs, elle permet de transmettre des messages clairs et concis de manière attrayante.",
        small_description: "Transformez votre créativité en compétence professionnelle avec notre formation en infographie, où chaque pixel raconte votre histoire.",
        long_description: "Plongez dans l'univers captivant de la création visuelle avec notre formation en infographie. Que vous soyez un débutant curieux ou un professionnel cherchant à affiner ses compétences, notre programme vous offre une immersion complète dans les techniques essentielles de conception graphique. Explorez les outils innovants, apprenez à manipuler les formes, les couleurs et les typographies pour donner vie à vos idées avec éclat. Nos instructeurs expérimentés vous guideront à travers des projets pratiques, vous permettant de développer votre style personnel tout en acquérant une compréhension approfondie des principes fondamentaux de la conception. Rejoignez-nous dans cette aventure artistique où chaque leçon vous rapprochera un peu plus de la maîtrise de l'art de l'infographie.",
        duration: 45,
        session: 12, 
        price: "50.000",
        worker: "infographe", 
        image_training :"../images/infographie-img.webp",
        image_certified : "../images/infographie-img.webp",
        contentModule: [
          {
            moduleid : 1,
              titlemodule: "Généralité",
              moduleItems: [
                  "Installation des logiciels",
                  "Types de logiciel",
                  "Révision ordinateur (les différents raccourcis)",
                  "Présentation Générale sur l’infographie",
                  "Image libre de droit",
                  
              ]
          },
          {
              moduleid : 2,
              titlemodule: "Photoshop", 
              moduleItems: [
                
                  "Les polices",
                  "Les couleurs",
                  "Le Mode de colorimétrique",
                  "Navigation dans Photoshop",
                  "Utilisation des panneaux et des barres d'outils",
                  "Compréhension des calques",
                  "Gestion des calques (création, suppression, duplication)",
                  "Insertion de texte et manipulation de calques de texte",
                  "Insertion des images",
                    "Détourage ",
                    "Les dégradés",
                    "Création d’Affiche avec PhotoShop +  TP ",
                  "Retouche photo",
                  "Manipulation Photo + TP",

              ]
          },
          {
              titlemodule: "Lightroom",
              moduleid : 3,
              moduleItems: [
                  "Présentation de lightroom",
                  "Interface de Lightroom",
                  "Rétourche photo avec Lightroom",
                  "Rétourche en masse de photo avec Lightroom",
                  "Exportation des catalogue",
              ]
          },
          {
            moduleid : 4,
              titlemodule: "Illustrator",
              moduleItems: [
                  "Présentation du logiciel",
                  "Prise en main des outils",
                  "Le détourage",
                  "Conception d’une affiche avec Illustraor + TP",
                  "La vectorisation",
                  "Calque model photo cartoon",
                  "Différents type de logo",
                  "Réalisation de logo + TP"
              ] 
          }
      ]
    },
{
        id: 2,
        title: "Montage Video",
        introduction : "Le montage vidéo est l'art de raconter des histoires à travers la manipulation et l'arrangement de séquences vidéo, d'images et de sons. Essentiel dans les domaines du cinéma, de la télévision, et de la création de contenu en ligne, le montage vidéo permet de transformer des bribes de vidéos brutes en œuvres captivantes et cohérentes. ",
        small_description: "Grâce à notre formation en montagne vidéo développer des compétences nécessaires à la création de vidéos captivantes et professionnelles.",
        long_description: "Notre formation en montage vidéo vous permettra de maîtriser les outils et techniques essentiels pour créer des vidéos captivantes et professionnelles. À travers des modules interactifs, vous apprendrez à utiliser des logiciels de pointe comme Adobe Premiere Pro et After Effects. Vous découvrirez les bases du montage, l'application des transitions et effets, ainsi que les techniques avancées d'animation et de correction des couleurs. ",
        duration: 60,
        session: 16, 
        price: "60.000",
        worker: "monteur vidéo", 
        image_training :"../images/montage-video-img.webp",
        image_certified : "../images/infographie-img.webp",
        contentModule: [
          {
            moduleid : 1,
              titlemodule: "Généralité",
              moduleItems: [
                  "Installation des logiciels",
                  "Types de logiciel",
                  "Révision ordinateur (les différents raccourcis)",
                  "Présentation Générale sur le cinéma et le montage vidéo",
                    "Types de logiciel",
                  "Image & Vidéo libre de droit",
                  
              ]
          },
          {
              moduleid : 2,
              titlemodule: "Filmora",
              moduleItems: [
                
                  "Présentation de l'interface",
                  "Navigation dans Filmora",
                  "Importation des assets",
                  "....",
                  "Montage de film d'anniversaire avec filmora"

              ]
          },
          {
              titlemodule: "Premiere Pro",
              moduleid : 3,
              moduleItems: [
                 "Présentation de l'interface",
                  "Navigation dans Premiere Pro",
                  "...",
                  "Importation et Organisation des Médias ",
                  "Édition de la Timeline",
                  "Transitions et Effets",
                  "Titres et Sous-titres",
                  "Exportation",
                  "Montage de documentaire dans premiere pro",
                  "Montage Multicaméra",
                  "Correction et Gradation des Couleurs",
                  "Mixage Audio",
                  
              ]
          },
          {
            moduleid : 4,
              titlemodule: "After Effect",
              moduleItems: [
                  "Présentation du logiciel",
                  "Prise en main des outils",
                  "Animation et Keyframes",
                  "Effets Visuels de Base ",
                  "Texte et Graphiques Animés",
                  "Masques et Rotoscoping",
                  "...",
                  "Effets Spéciaux",
                  "Tracking et Stabilisation",
                  "Expressions et Scripts",
              ] 
          }
      ]
    },
{
        id: 3,
        title: "Bureautique",
        introduction : "Dans un monde de plus en plus numérique, la maîtrise des outils informatiques est devenue une nécessité incontournable. Que ce soit pour des raisons professionnelles ou personnelles, savoir utiliser efficacement un ordinateur et ses logiciels est essentiel.",
        small_description: "Inscrivez-vous à notre formation en bureautique pour maîtriser les outils essentiels et augmenter votre productivité au travail.",
        long_description: "Notre formation en informatique générale offre de nombreux avantages pour quiconque souhaite se familiariser avec le monde numérique. En participant à notre programme, vous acquerrez une solide compréhension des principes de base de l'informatique, y compris la gestion des systèmes d'exploitation, la navigation sur Internet, et l'utilisation des logiciels bureautiques courants.",
        duration: 30,
        session: 12, 
        price: "30.000",
        worker: " ", 
        image_training :"../images/bureautique.webp",
        image_certified : "../images/infographie-img.webp",
        contentModule: [
          {
            moduleid : 1,
              titlemodule: "Généralité",
              moduleItems: [
                  "Nécessité de l'informatique ",
                  "Les différents types l'ordinateur & leurs composants",
                  "Les systèmes d'exploitation",
                  "Création de dossier, fichier",
                  "Navigation sur internet",
                  "Envoie de mail",
                  "..."

              ]
          },
          {
              moduleid : 2,
              titlemodule: "Word",
              moduleItems: [
                  "Introduction à Microsoft Word",
                  "Présentation de l'interface Word",
                  "Saisie et mise en forme de texte",
                  "Paragraphes et alignements",
                  "Listes et puces",
                  "Outils de Mise en Forme Avancés",
                  "Outils de Révision et Collaboration",

              ]
          },
          {
              titlemodule: "PowerPoint",
              moduleid : 3,
              moduleItems: [
                "Introduction à Microsoft PowerPoint",
                "Présentation de l'interface PowerPoint",
                "Conception et Mise en Page ",
                " Utilisation des graphiques et tableaux",
                " Ajout de multimédia",
                " Navigation et interaction avec la présentation",
                " Maîtrise du mode diaporama",
                "...",
              ]
          },
          {
            moduleid : 4,
              titlemodule: "Excel",
              moduleItems: [
                  " Présentation de l'interface Excel",
                  "Saisie de données et formats de cellule ",
                  " Formules et fonctions de base",
                  " Gestion des feuilles de calcul",
                  "Analyse de Données et Visualisation ",
                  "Validation des données ",
                  "... ",
                  
              ] 
          }
      ]
    },
{
        id: 4,
        title: "Programmation",
        introduction : "Dans un monde où la technologie est omniprésente, apprendre la programmation informatique est devenu une compétence essentielle. La programmation permet de comprendre et de contrôler les systèmes numériques qui façonnent notre quotidien.",
        small_description: "Cette formation couvre divers langages de programmation, paradigmes, et outils qui permettent de créer des applications et des systèmes informatiques.",
        long_description: "Notre formation en programmation offre de nombreux avantages pour les débutants comme pour les professionnels souhaitant approfondir leurs compétences. En suivant notre programme, vous acquerrez une compréhension solide des langages de programmation les plus demandés.",
        duration: 30,
        session: 12, 
        price: "35.000",
        worker: "Devs Junior", 
        image_training :"../images/programmation.webp",
        image_certified : "../images/infographie-img.webp",
        contentModule: [
          {
            moduleid : 1,
              titlemodule: "Généralité",
              moduleItems: [
                  "....",
                  "....",
                  "....",
                  "....",
                "....",
                  "....",
                  
              ]
          },
          {
              moduleid : 2,
              titlemodule: "Développement web",
              moduleItems: [
                
                  "HTML",
                  "CSS",
                  "JS",
                  "SASS",
                  "BOOTSTRAP",

              ]
          },
          {
              titlemodule: "Développement mobile",
              moduleid : 3,
              moduleItems: [
                  "...",
                  "...",
                  "...",
                  "...",
                  "...",
              ]
          },
          {
            moduleid : 4,
              titlemodule: "Développement bureau",
              moduleItems: [
                "...",
                "...",
                "...",
                "...",
                "...",
              ] 
          }
      ]
    },
{
        id: 5,
        title: "Reseaux",
        introduction : "Dans une ère où la connectivité et la communication numérique sont au cœur de presque toutes les activités humaines, l'apprentissage des réseaux informatiques est devenu crucial. Les réseaux informatiques sont la colonne vertébrale de notre société moderne, facilitant le transfert de données, l'accès à l'information et la communication instantanée à l'échelle mondiale.",
        small_description: "Cette formation en réseau est essentielle pour les professionnels ou les débutants qui souhaitent concevoir, installer, et maintenir des infrastructures de câblage réseau efficaces et fiables.",
        long_description: "Notre formation en réseau informatique offre une pléthore d'avantages pour les aspirants et les professionnels souhaitant approfondir leurs compétences dans ce domaine essentiel. En suivant notre programme, vous développerez une compréhension approfondie des concepts fondamentaux des réseaux, tels que la configuration, la gestion et la sécurisation des infrastructures réseau. ",
        duration: 2,
        session: 4, 
        price: "35.000",
        worker: "Devs Junior", 
        image_training :"../images/network.webp",
        image_certified : "../images/infographie-img.webp",
        contentModule: [
          {
            moduleid : 1,
              titlemodule: "Généralité",
              moduleItems: [
                "...",
                "...",
                "...",
                "...",
                "...",
                  
              ]
          },
          {
              moduleid : 2,
              titlemodule: "Configuration Mikrotik",
              moduleItems: [
                
                "...",
                "...",
                "...",
                "...",
                "...",

              ]
          },
          {
              titlemodule: "Configuration Routeur",
              moduleid : 3,
              moduleItems: [
                "...",
                "...",
                "...",
                "...",
                "...",
              ]
          },
      ]
    },
{
        id: 5,
        title: "Videosurveillance",
        introduction : "La vidéosurveillance est un système de sécurité qui utilise des caméras et des équipements associés pour surveiller, enregistrer, et analyser les activités dans un environnement donné. Les systèmes de vidéosurveillance sont couramment utilisés pour assurer la sécurité dans divers contextes, tels que les entreprises, les espaces publics, les propriétés résidentielles, et les établissements gouvernementaux.",
        small_description: "Comprendre les principes fondamentaux de la vidéosurveillance et les différents types de systèmes (analogiques / IP)",
        long_description: "Notre formation en vidéosurveillance est essentielle pour acquérir les compétences nécessaires à l'installation, la gestion et la maintenance des systèmes de surveillance vidéo. Une formation complète couvre divers aspects, de la compréhension des équipements à l’analyse des données vidéo, en passant par les considérations légales et éthiques.",
        duration: 2,
        session: 4, 
        price: "30.000",
        worker: "Installateur caméra", 
        image_training :"../images/cctv.webp",
        image_certified : "../images/infographie-img.webp",
        contentModule: [
          {
            moduleid : 1,
              titlemodule: "Généralité",
              moduleItems: [
                "...",
                "...",
                "...",
                "...",
                "...",
                  
              ]
          },
          {
              moduleid : 2,
              titlemodule: "Configuration DVR/NVR",
              moduleItems: [
                
                "...",
                "...",
                "...",
                "...",
                "...",

              ]
          },
          {
              titlemodule: "Configuration Caméra",
              moduleid : 3,
              moduleItems: [
                "...",
                "...",
                "...",
                "...",
                "...",
              ]
          },
      ]
    },



];
export const testimonials = [
    {
        id: "1",
        name: "Kouadio Marc (CIV) ",
        module: "Infographie (Présentiel) ",
        Msg: "La formation en infographie a dépassé mes attentes. Les outils sont expliqués de manière claire et pratique. J'ai pu réaliser mes premières créations graphiques en un rien de temps !",

    },
    {
        id: "2",
        name: "Moussa Doumbia (CIV)",
        module: "Montage Vidéo (Présentiel)",
        Msg: "J'ai appris à maîtriser les logiciels de montage vidéo en un temps record ! Le formateur est très compétent et a su nous donner des astuces pour rendre nos vidéos professionnelles.s",

    },
    {
        id: "3",
        name: "Sery Dorcas (CIV)",
        module: "Programmation (En Ligne)",
        Msg: "La formation en programmation était top ! Je suis parti de zéro et, grâce à des exercices pratiques, j'ai pu créer mes premières applications fonctionnelles. Une expérience très enrichissante.",

    },
    {
        id: "4",
        name: "Agbo Achille (Togo)", 
        module: "Bureautique (En ligne) ",
        Msg: "Le module bureautique est bien structuré et facile à suivre. J'ai amélioré ma maîtrise de Word, Excel et PowerPoint, ce qui me rend plus efficace au travail.",

    },
    {
        id: "5",
        name: "Diallo Adama (Guinée) ",
        module: "Câblage réseau (En Ligne)",
        Msg: "Grâce à cette formation, j'ai acquis des compétences pratiques en câblage réseau. Les démonstrations en direct étaient très utiles pour comprendre les étapes.",

    },
    {
        id: "6",
        name: "Diallo Adama (Guinée) ",
        module: "Câblage réseau (En Ligne)",
        Msg: "Grâce à cette formation, j'ai acquis des compétences pratiques en câblage réseau. Les démonstrations en direct étaient très utiles pour comprendre les étapes.",

    },
    {
        id: "7",
        name: "Ekobo Rachelle (Cameroun ) ",
        module: "Administration réseau avec Mikrotik (En Ligne)",
        Msg: "La formation sur Mikrotik m'a permis de comprendre les rouages de l'administration réseau. Le formateur a su rendre les concepts complexes accessibles, et je me sens maintenant capable de gérer des réseaux professionnels.",

    },
]